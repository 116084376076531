/* You can add global styles to this file, and also import other style files */

.ng-autocomplete {
    width: 100%;
    max-width: 600px;
    display: table;
    margin: 0 auto;
}

.paginate_button {
    cursor: pointer !important;
    color: #0baaa2;
    font-weight: bold;
}

.card-header {
    background-color: #0baaa2 !important;
}

.card-title {
    color: white !important;
}

.spl-items h6 {
	color: #102c53 !important;
}

.spl-items:hover h6 {
	color: #fff !important;
}

.spl-items i {
	color: #102c53 !important;
}

.spl-items:hover i {
	color: #fff !important;
}

.spl-items {
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #EEF1FE;
	box-shadow: 0px 7px 10px #EEF1FE;
	border-radius: 5px;	
	margin-bottom: 16px;
}
.spl-items a {	
	padding: 30px;
	display: flex;
    display: -ms-flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.spl-items:hover {
	background: #0baaa2 !important;
}

.spl-items a:hover i, .spl-items a:hover h6 {
	color: #FFF;
}
.spl-items h6 {
	color: #102c53 !important;
}

.btn-primary {
	background-color: #0baaa2 !important;
	border: 1px solid #0baaa2 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #0baaa2 !important;
	border: 1px solid #0baaa2 !important;
}


.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #0baaa2 !important;
	border: 1px solid #0baaa2 !important;
}


.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #0baaa2 !important;
	border-color: #0baaa2 !important;
	color: #fff !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #0baaa2 !important;
	border-color: #0baaa2 !important;
	color: #fff !important;
}

/* LABELS */

.label{display:inline;padding:3px 6px 4px;font-size:75%;font-weight:600;color:#fff;text-align:center;white-space:nowrap;vertical-align:baseline;-webkit-border-radius:3px;border-radius:3px}

.label.label-primary{background:#348fe2}

.label.label-secondary{background:#6c757d}

.label.label-success{background:#00acac}

.label.label-info{background:#49b6d6}

.label.label-warning{background:#f59c1a}

.label.label-danger{background:#ff5b57}

.label.label-light{background:#fff;color:#2d353c}

.label.label-dark{background:#2d353c}

.label.label-inverse{background:#2d353c}

.label.label-black{background:#000}

.label.label-white{background:#fff}

.label.label-grey{background:#b6c2c9}

.label.label-muted{background:#b6c2c9}

.label.label-silver{background:#b6c2c9}

.label.label-lime{background:#90ca4b}

.label.label-aqua{background:#49b6d6}

.label.label-blue{background:#348fe2}

.label.label-indigo{background:#8753de}

.label.label-purple{background:#727cb6}

.label.label-pink{background:#fb5597}

.label.label-red{background:#ff5b57}

.label.label-orange{background:#f59c1a}

.label.label-yellow{color:#2d353c;background:#ffd900}

.label.label-green{background:#32a932}

.label.label-teal{background:#00acac}

.label.label-cyan{background:#49b6d6}

.label.label-white{background:#fff}

.label.label-gray{background:#6c757d}

.label.label-gray-dark{background:#343a40}

.label.label-black{background:#000}

.label.label-dark{background:#2d353c}

.label.label-dark-darker{background:#1a2229}

.label.label-lime{background:#90ca4b}

.label.label-light{background:#f2f3f4}

.label.label-silver{background:#b6c2c9}

.label.label-muted{background:#627884}

.label.label-aqua{background:#49b6d6}

/* CUSTOM */

.text-blue {
	color: #102C53;
}

.text-green {
	color: #08A9A1;
}

/* Steps Styles */

.banner {
    margin-top: 85px;
}

.banner-title {
    background-color: #08A9A1;
    height: 80px;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: center;
    padding: 15px;
    position: relative;
}

.banner-icon {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 20px;
    bottom: 70px;

    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
}

.banner-icon img {
    width: 50px;
    margin-top: 5px;
}

.assessment-button {
    width: 100%;
    height: 80px;

    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 11px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;

    color: #102C53;
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
}

.assessment-input {
    width: 100%;
    height: 80px;

    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
    padding: 30px;

    color: #102C53;
    text-align: center;
    margin-top: 50px;
}

.assessment-button img {
    width: 40px;
    margin-bottom: 5px;
    margin-top: 0;
}

.assessment-container {
    padding-bottom: 150px;
}

.assessment-section {
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    background: #FFFFFF;
    border: 4px solid rgba(11, 170, 162, 0.4);
    border-radius: 25px;
    margin-top: 100px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: pointer;
    padding: 10px;
}

.assessment-section img {
    width: 120px;
    margin: 150px 0 0 0;
}

.assessment-section div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 48px;
    color: #102C53;
}

.module-icon {
    margin-top: 100px;
    width: 70px;
}

.module-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #102C53;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
    color: #102C53 !important;
    font-weight: normal !important;
    margin-bottom: 30px !important;
    font-size: 24px !important;
}

.pt32 {
    padding-top: 32px !important;
}

.pt40 {
    padding-top: 40px !important;
}


/*-----------------
	28. Responsive
-----------------------*/



@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (max-width: 1399px) {

}

@media (max-width: 991.98px) {

}

@media (max-width: 767.98px) {

}

.header .header-left {
    position: absolute;
    width: 100%;
    padding-left: 40px;
}

/* .mobile_btn {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10;
} */
#toggle_btn {
    display: none;
}
.sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
}
.page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}